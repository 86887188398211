import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import HeroSection from "../components/herosection";
import aboutuswhowearehero from "../images/about-us-who-we-are-hero.jpg";
import aboutusourcorevalueshero from "../images/about-us-our-core-values-hero.jpg";
import SingleButton from "../components/singlebutton";
import Arrow from "../components/arrow";
import Headerlanding from '../components/navbar-landing.js';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import seohero from "../images/seo-hero.jpg";

import rowsixhero1 from "../images/organic-search-row-six-hero-1.png";
import rowsixhero2 from "../images/organic-search-row-six-hero-2.png";
import rowsixhero3 from "../images/organic-search-row-six-hero-3.png";
import rowsixhero4 from "../images/organic-search-row-six-hero-4.png";

import rowsixhero1mob from "../images/organic-search-row-six-hero-1-mob.png";
import rowsixhero2mob from "../images/organic-search-row-six-hero-2-mob.png";
import rowsixhero3mob from "../images/organic-search-row-six-hero-3-mob.png";
import rowsixhero4mob from "../images/organic-search-row-six-hero-4-mob.png";


import amcal from "../images/logos/amcal.png";
import getwinesdirect from "../images/logos/getwinesdirect.png";
import loans from "../images/logos/loans.png";
import youi from "../images/logos/youi.png";

import "../scss/seo.scss";
import "../scss/contactForm.scss"

class SeoPage extends React.Component{
    render() {
	const metadescription = "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950.";
	const metatitle = "SEO - Next&Co";
	const metaurl = typeof window !== 'undefined' ? window.location.href : '';

	return (
	    <>
		<Helmet>
		    <title>{metatitle}</title>
		    <meta name="description" content={metadescription}/>
		    <meta name="title" content={metatitle} />
		    <meta property="og:type" content="website" />
		    <meta property="og:url" content={metaurl} />
		    <meta property="og:title" content={metatitle} />
		    <meta property="og:description" content={metadescription} />
		    <meta property="og:image" content="facebook.png" />
		    <meta property="twitter:url" content={metaurl} />
		    <meta property="twitter:title" content={metatitle} />
		    <meta property="twitter:description" content={metadescription} />
		    <meta property="twitter:image" content="twitter.png" />
		</Helmet>
		<Headerlanding />
		<div className="seo-page">

		    <section id="row-one"
			     style={{
				 backgroundImage: `url(${seohero})`,
				 backgroundPosition: `center center`,
				 backgroundRepeat: `no-repeat`,
				 backgroundSize: `cover`,
			     }}
		    >
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container">
				    <div>
					<h1 className="">
					    Performance guaranteed, sustainable SEO strategy to scale your brand.
					</h1>
					<div>
					    <h4>Complete solutions for</h4>
					    <ul>
						<li>Enterprise SEO</li>
						<li>
						    E-Commerce SEO
						</li>
						<li>
						    National & International SEO
						</li>
					    </ul>
					</div>
				    </div>
				</div>
			    </div>
			</div>
		    </section>
		    <section id="lets-talk" class="" style={{paddingTop: `2em`, paddingBottom: `4em`,}}>
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container">
				    <div className="row" style={{justifyContent : `space-around`,}}>
					<div className="column" style={{flex:`1`,}}>
					    <div className="row">
						<h4 style={{color:`black`,}}>
						    Assess your current SEO performance
						</h4>
					    </div>
					    <div className="row" style={{margin:`auto 0 0`}}>
						<Link
						    className="btn btn-invert"
						    to="#row-three">
						    Get an SEO audit
						</Link>
					    </div>
					</div>

					<div className="column" style={{flex:`1`,}}>
					    <div className="row">
						<h4 style={{color:`black`,}}>
						    Lets talk
						</h4>
					    </div>
					    <div className="row" style={{margin:`auto 0 0`}}>
						<Link
						    className="btn btn-invert"
						    to="#contact-form-container">
						    Get in touch
						</Link>
					    </div>
					</div>
				    </div>
				</div>
			    </div>
			</div>
		    </section>
		    <section class="audit-hero-section">
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container row">
				    <div className="column-container">
				    <div className="col-one">
					<div className="image-container" style={{
						 height : `2em`,
						 display: `flex`,
						 alignItems: `center`,
					     }}>
					    <img className="" alt="" src={getwinesdirect} style={{
						     maxWidth : `150px`,
						     maxHeight : `2em`,
						 }} />
					</div>

					<div className="list-item">
					    278% increase in new customer acquisition <br/>— GetWinesDirect
					</div>
				    </div>
				    <div className="col-one">
					<div className="image-container" style={{
						 height : `2em`,
						 display: `flex`,
						 alignItems: `center`,
					     }}>
					    <img className="" alt="" src={loans} style={{
						     maxWidth : `150px`,
						     maxHeight : `2em`,
						 }} />

					</div>
					
					<div className="list-item">
					    145% increase in organic traffic <br/>— Loans.com.au
					</div>
				    </div>
				    <div className="col-one">
					<div className="image-container" style={{
						 height : `2em`,
						 display: `flex`,
						 alignItems: `center`,
					     }}>
					    <img className="" alt="" src={amcal} style={{
						     maxWidth : `150px`,
						     maxHeight : `2em`,
						 }} />
					</div>
					<div className="list-item">
					    95% increase in organic traffic <br/>— Amcal
					</div>
				    </div>
				    <div className="col-one">
					<div className="image-container" style={{
						 height : `2em`,
						 display: `flex`,
						 alignItems: `center`,
					     }}>
					    <img className="" alt="" src={youi} style={{
						     maxWidth : `150px`,
						     maxHeight : `2em`,
						 }} />
					</div>
				<div className="list-item">
					    103% increase in organic traffic <br/>— Youi
					</div>
				    </div>
</div>
				</div>
			    </div>
			</div>
		    </section>
		    <section id="methodology" style={{paddingBottom: `3em`,}}>
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container">
				    <div className="small-inner-container" style={{textAlign : `center`,}}>
					<h2 className="col-one-heading">
					    Our methodology & what we do
					</h2>
					<p style={{marginBottom : `3em`}}>
					    We take a holistic approach to improving your brands organic search presence. We reverse engineer the ranking factors of the top ranked websites and come up with a precise roadmap of activity to rank your brands domain for its target phrases. Optimising both your website (on-page SEO) as well as your back-link profile (off-page SEO) to generate results that are brand-safe and sustainable.
					</p>
					<div className="row">
					    <img className="show-desktop" alt="" src={rowsixhero1}/>
					    <img className="show-desktop" alt="" src={rowsixhero2}/>
					    <img className="show-mobile" alt="" src={rowsixhero1mob}/>
					    <img className="show-mobile" alt="" src={rowsixhero2mob}/>											    
					</div>
					<div className="row">
					    <img className="show-desktop" alt="" src={rowsixhero3}/>
					    <img className="show-desktop" alt="" src={rowsixhero4}/>
					    <img className="show-mobile" alt="" src={rowsixhero4mob}/>
					    <img className="show-mobile" alt="" src={rowsixhero3mob}/>
					</div>
				    </div>
				</div>
			    </div>
			</div>
		    </section>
		    <section id="row-three" class="audit-hero-section">
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container row">
				    <div className="col-one">
					<h2 className="col-one-heading">SEO Audit</h2>
					<h5>Understand the opportunity for your brand in the organic search space and how to capitalise on it</h5>
					<ul>
					    <li>Search trends</li>
					    <li>Market analysis</li>
					    <li>Competitor analysis</li>
					    <li>Actionable Roadmap</li>
					</ul>
				    </div>
				    <div className="col-two">
					<div className="form-container">
					    <form
						name="seo-audit"
						method="post"
						action="/osa-thankyou/"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
					    >
						{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
						<input type="hidden" name="form-name" value="os-audit" />
						<p hidden>
						    <label>
							Don’t fill this out:{" "}
							<input name="bot-field"/>
						    </label>
						</p>
						<p></p>
						<p>
						    <label>
							Your Name
							<input
							    type="text"
							    required
							    name="your-name"
							    placeholder="Your name"
							/>
						    </label>
						</p>
						<p>
						    <label>
							URL
							<input
							    type="text"
							    required
							    name="website-url"
							    placeholder="Website URL"
							/>
						    </label>
						</p>
						<p>
						    <label>
							Email
							<input
							    type="email"
							    required
							    name="email"
							    placeholder="Your email"
							/>
						    </label>
						</p>
						<p>
						    <label>
							Phone
							<input
							    type="tel"
							    name="phone"
							    required
							    placeholder="Your phone"
							/>
						    </label>
						</p>
						<p>
						    <button className="btn btn-invert" type="submit">
							Get your audit
						    </button>
						</p>
					    </form>
					</div>
				    </div>
				    
				</div>
			    </div>
			</div>
		    </section>

		    <section id="row-four"
			     style={{
				 backgroundImage: `url(${aboutuswhowearehero})`,
				 backgroundPosition: `center center`,
				 backgroundRepeat: `no-repeat`,
				 backgroundSize: `cover`,
				 paddingTop: `2em`,
				 paddingBottom: `2.25em`,
				 color: `white`,
			     }}
		    >
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container">
				    <div className="small-inner-container">
					<div className="col-one">
					    <h3>About Next&Co</h3>
					    <p>
						Founded in 2010, we exist to make the digital world a better place. Our goal is to work with our clients to define what ‘better’ looks like and how it can be measured in a digital environment.
					    </p>
					    <p>
						More sales, enquiries, downloads, or any other form of engagement – we take accountability for measuring the outcomes of our work and the pursuit of continuous improvement.
					    </p>
					</div>
				    </div>
				</div>
			    </div>
			</div>
		    </section>
		    <section id="contact-form-container"
			     style={{backgroundColor: `#fafafa`,}}>
			<div className="outer-container">
			    <div className="container-max-width">
				<div className="inner-container">
				    <div className="col">
					<div className="form-title">
					    <h3>Get in touch</h3>
					</div>
					<form
					    name="contact-form-seo"
					    method="post"
					    action="/thankyou"
					    data-netlify="true"
					    data-netlify-honeypot="bot-field"
					    onSubmit={this.handleSubmit}
					>
					    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					    <input type="hidden" name="form-name" value="contact" />
					    <p hidden>
						<label>
						    Don’t fill this out:{" "}
						    <input name="bot-field" onChange={this.handleChange} />
						</label>
					    </p>
					    <p className="field">
						<input
						    type="text"
						    required
						    name="name"
						    id="name"
						    placeholder=""
						    onChange={this.handleChange}
						/>
						<label>
						    Name
						</label>
					    </p>
					    <p className="field">
						<input
						    type="email"
						    required
						    name="email"
						    id="email"
						    placeholder=""
						    onChange={this.handleChange}
						/>
						<label>
						    Email
						</label>
					    </p>
					    <p className="field">
						<input
						    type="phone"
						    required
						    name="phone"
						    id="phone"
						    placeholder=""
						    onChange={this.handleChange}
						/>
						<label>
						    Phone
						</label>
					    </p>
					    <p className="field">
						<textarea
						    name="message"
						    id="message"
						    placeholder=""
						    required
						    onChange={this.handleChange}
						/>
						<label>
						    Message
						</label>
					    </p>
					    <p>
						<button type="submit">Send</button>
					    </p>
					</form>
				    </div>
				    <div className="col">
					<p>
					    <a target="_blank" rel="noopener noreferrer" className="address" href="https://www.google.com/maps/place/Level+3%2F424+St+Kilda+Rd,+Melbourne+VIC+3004/@-37.8367959,144.9726852,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad6681d09ea7015:0xf876defa18af960b!8m2!3d-37.8368002!4d144.9748792">Level 3 424 St Kilda Road, Melbourne, Victoria 3004</a>
					</p>
					<p>
					    <a className="telephone"href="tel:1300191950">1300 191 950</a>
					</p>
					<p>
					    <a className="email"href="mailto:enquiries@nextandco.com.au?subject=Next and Co Enquiry">enquiries@nextandco.com.au</a>
					</p>
				    </div>
				</div>
			    </div>
			</div>
		    </section>
		</div>
	    </>


	)
    }
}


export default SeoPage;
